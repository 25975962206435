import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CardBlog from '../components/card-blog'

export default class Blog extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO 
          title="Articles" 
          keywords={[`gatsby`, `application`, `react`]} />
        <h1>My Articles</h1>
        <ul className="blog-roll__list">
          {data.allWordpressPost.edges.map(item => (
            <li className="blog-roll__list-item" key={item.node.wordpress_id}>
              <CardBlog data={item.node} />
            </li>
          ))}
        </ul>
      </Layout>
    )
  }
}

export const query = graphql`
  query ArticesQuery {
    allWordpressPost {
      edges {
        node {
          wordpress_id
          type
          slug
          title
          excerpt
          categories {
            name
          }
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                resize(width: 350, height: 320, cropFocus: CENTER) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
